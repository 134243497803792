import type { ReactElement, ReactNode } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { SWRDevTools } from 'swr-devtools';
import { ThemeProvider } from 'styled-components';
import { SWRConfig } from 'swr';
import Head from 'next/head';
import { Inter } from 'next/font/google';

import { MainLayout } from 'views/hoc/Layouts/MainLayout';
import fetcher from 'utils/axios/fetcher';
import GlobalStyle from 'views/components/GlobalStyle';
import { lightTheme } from 'theme';
import 'utils/axios/config';

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export const inter = Inter({
  weight: ['400', '500', '600'],
  display: 'swap',
  subsets: ['latin'],
});

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout ?? ((page) => <MainLayout>{page}</MainLayout>);

  return (
    <>
      <Head>
        <title>Hydrolix</title>
        <link rel="shortcut icon" href="/icon-256x256.png" />

        <link rel="manifest" href="/manifest.json" />

        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="description" content="Hydrolix" />
        <meta name="keywords" content="Keywords" />

        <meta name="apple-mobile-web-app-status-bar" content="#fff" />

        <link href="/icons/pwa/favicon-16x16.png" rel="icon" type="image/png" sizes="16x16" />
        <link href="/icons/pwa/favicon-32x32.png" rel="icon" type="image/png" sizes="32x32" />
        <link rel="apple-touch-icon" href="/icons/pwa/apple-touch-icon-iphone-60x60.png" />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/icons/pwa/apple-touch-icon-iphone-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/icons/pwa/apple-touch-icon-iphone-retina-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/icons/pwa/apple-touch-icon-ipad-retina-152x152.png"
        />
        <meta name="theme-color" content="#fff" />
        <meta name="mobile-web-app-capable" content="yes" />

        <meta name="application-name" content="Hydrolix" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="Hydrolix" />

        <link rel="mask-icon" href="/icons/pwa/favicon-32x32.png" />

        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/icons/pwa/splash_screens/12.9__iPad_Pro_landscape.png"
          sizes="1024x1366"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/icons/pwa/splash_screens/11__iPad_Pro__10.5__iPad_Pro_landscape.png"
          sizes="834x1194"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 820px) and (device-height: 1180px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/icons/pwa/splash_screens/10.9__iPad_Air_landscape.png"
          sizes="820x1180"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/icons/pwa/splash_screens/10.5__iPad_Air_landscape.png"
          sizes="834x1112"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/icons/pwa/splash_screens/10.2__iPad_landscape.png"
          sizes="810x1080"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/icons/pwa/splash_screens/9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_landscape.png"
          sizes="768x1024"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href="/icons/pwa/splash_screens/iPhone_14_Pro_Max_landscape.png"
          sizes="430x932"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href="/icons/pwa/splash_screens/iPhone_14_Pro_landscape.png"
          sizes="393x852"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href="/icons/pwa/splash_screens/iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_landscape.png"
          sizes="428x926"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href="/icons/pwa/splash_screens/iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_landscape.png"
          sizes="390x844"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href="/icons/pwa/splash_screens/iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_landscape.png"
          sizes="375x812"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href="/icons/pwa/splash_screens/iPhone_11_Pro_Max__iPhone_XS_Max_landscape.png"
          sizes="414x896"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/icons/pwa/splash_screens/iPhone_11__iPhone_XR_landscape.png"
          sizes="414x896"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href="/icons/pwa/splash_screens/iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_landscape.png"
          sizes="414x736"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/icons/pwa/splash_screens/iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_landscape.png"
          sizes="375x667"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href="/icons/pwa/splash_screens/4__iPhone_SE__iPod_touch_5th_generation_and_later_landscape.png"
          sizes="320x568"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/icons/pwa/splash_screens/12.9__iPad_Pro_portrait.png"
          sizes="1024x1366"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/icons/pwa/splash_screens/11__iPad_Pro__10.5__iPad_Pro_portrait.png"
          sizes="834x1194"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 820px) and (device-height: 1180px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/icons/pwa/splash_screens/10.9__iPad_Air_portrait.png"
          sizes="820x1180"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/icons/pwa/splash_screens/10.5__iPad_Air_portrait.png"
          sizes="834x1112"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/icons/pwa/splash_screens/10.2__iPad_portrait.png"
          sizes="810x1080"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/icons/pwa/splash_screens/9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_portrait.png"
          sizes="768x1024"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href="/icons/pwa/splash_screens/iPhone_14_Pro_Max_portrait.png"
          sizes="430x932"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href="/icons/pwa/splash_screens/iPhone_14_Pro_portrait.png"
          sizes="393x852"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href="/icons/pwa/splash_screens/iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_portrait.png"
          sizes="428x926"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href="/icons/pwa/splash_screens/iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_portrait.png"
          sizes="390x844"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href="/icons/pwa/splash_screens/iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_portrait.png"
          sizes="375x812"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href="/icons/pwa/splash_screens/iPhone_11_Pro_Max__iPhone_XS_Max_portrait.png"
          sizes="414x896"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/icons/pwa/splash_screens/iPhone_11__iPhone_XR_portrait.png"
          sizes="414x896"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href="/icons/pwa/splash_screens/iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_portrait.png"
          sizes="414x736"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/icons/pwa/splash_screens/iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_portrait.png"
          sizes="375x667"
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href="/icons/pwa/splash_screens/4__iPhone_SE__iPod_touch_5th_generation_and_later_portrait.png"
          sizes="320x568"
        />
      </Head>
      <SWRDevTools>
        <SWRConfig
          value={{
            fetcher,
            shouldRetryOnError: false,
          }}
        >
          <ThemeProvider theme={lightTheme}>
            {getLayout(
              <div className={inter.className}>
                <GlobalStyle />
                <Component {...pageProps} />
              </div>,
            )}
          </ThemeProvider>
        </SWRConfig>
      </SWRDevTools>
    </>
  );
};

export default App;
