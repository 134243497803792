import useSWR from 'swr';
import axios from 'axios';

const useClusterVersionStore = () => {
  return useSWR<VersionObject, ResponseError>('cluster-version', () =>
    axios.get(`${window.location.origin}/version`),
  );
};

export default useClusterVersionStore;
