import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useMemo } from 'react';

import { useCurrentPage } from 'src/hooks/router';
import { useTranslation } from 'src/hooks/translation';
import { useClusterVersionStore, useOpenSidebar } from 'store/common';
import * as UI from 'styles/sidebar';
import { Text } from 'styles/typography';
import { ROUTES } from 'utils/constants/routes';
import NavItemDocSection from 'views/features/Sidebar/NavItemDocSection';

interface NavigationProps {
  name: string;
  href: string;
  iconSrc: string;
  isExternal?: boolean;
}

const getNavList = (t: TranslationType): NavigationProps[] => [
  {
    name: t.sidebar.dashboard,
    href: ROUTES.dashboard,
    iconSrc: '/icons/nav/dashboard.svg',
  },
  {
    name: t.sidebar.systemHealth,
    href: ROUTES.systemHealthCounts,
    iconSrc: '/icons/nav/systemHealth.svg',
  },
  {
    name: t.sidebar.data,
    href: ROUTES.dataTables,
    iconSrc: '/icons/nav/data.svg',
  },
  {
    name: t.sidebar.jobs,
    href: ROUTES.alterTables,
    iconSrc: '/icons/nav/jobs.svg',
  },
  {
    name: t.sidebar.scale,
    href: ROUTES.scaleTables,
    iconSrc: '/icons/nav/scale.svg',
  },
  {
    name: t.sidebar.query,
    href: ROUTES.query,
    iconSrc: '/icons/nav/query.svg',
  },
  {
    name: t.sidebar.security,
    href: ROUTES.users,
    iconSrc: '/icons/nav/security.svg',
  },
  {
    name: t.sidebar.support,
    href: 'https://support.hydrolix.io/',
    iconSrc: '/icons/nav/support.svg',
    isExternal: true,
  },
  {
    name: t.sidebar.docs,
    href: 'https://docs.hydrolix.io/',
    iconSrc: '/icons/nav/docs.svg',
    isExternal: true,
  },
];

const ClusterVersion = ({ version }: { version: string | undefined }) => {
  return (
    <Text $color="#6D6E6F" data-testid="sidebar-cluster-version">
      {version ?? ''}
    </Text>
  );
};

const Sidebar: FC = () => {
  const { pathname } = useRouter();
  const t = useTranslation();
  const { data: versionData, error } = useClusterVersionStore();
  const isErrors = Object.keys(error ?? {}).length !== 0;

  const navList = useMemo(() => getNavList(t), [t]);

  const { getIsActivePage } = useCurrentPage({ pathname });

  const { data: isOpen } = useOpenSidebar();

  return (
    <UI.Sidebar $width={isOpen ? 180 : 62}>
      <UI.Wrapper>
        <nav>
          <UI.NavList>
            {navList.map(({ name, href, iconSrc, isExternal }) => {
              const { isActivePage, isStartPage } = getIsActivePage({
                href,
                isExternal: !!isExternal,
              });

              const path = isStartPage ? '/' : href;

              return (
                <Link key={href} href={path} passHref target={isExternal ? '_blank' : ''}>
                  <UI.NavItem as="span" $isActive={isActivePage}>
                    <UI.IconWrapper>
                      <UI.Icon src={iconSrc} layout="fill" alt={'icon'} $isActive={isActivePage} />
                    </UI.IconWrapper>
                    <UI.Name $isOpen={!!isOpen}>{name}</UI.Name>
                  </UI.NavItem>
                </Link>
              );
            })}
          </UI.NavList>
          <UI.NavBreak />
          <NavItemDocSection isOpen={!!isOpen} />
        </nav>
        {!isErrors && <ClusterVersion version={versionData?.data} />}
      </UI.Wrapper>
    </UI.Sidebar>
  );
};

export default Sidebar;
